import React from 'react';
import { bool } from 'prop-types';

import PriceFilterPlain from './PriceFilterPlain';
import PriceFilterPopup from './PriceFilterPopup';
import { withViewport } from '../../../util/uiHelpers';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const PriceFilterComponent = props => {
  const { marketplaceCurrency, viewport, ...rest } = props;
  const showAsPopup = viewport.width > MAX_MOBILE_SCREEN_WIDTH;

  return showAsPopup ? (
    <PriceFilterPopup marketplaceCurrency={marketplaceCurrency} {...rest} />
  ) : (
    <PriceFilterPlain marketplaceCurrency={marketplaceCurrency} {...rest} />
  );
};

PriceFilterComponent.defaultProps = {
  showAsPopup: false,
};

PriceFilterComponent.propTypes = {
  showAsPopup: bool,
};

const PriceFilter = withViewport(PriceFilterComponent);

export default PriceFilter;
